html, body {
  background-color  : #aeafb1 !important;
  font-family       : adobe-clean, sans-serif, Arial, monospace;
  color             : #2b2b2c;
}

p {
  font-size: 16px !important;
}

#root {
  max-width : 1200px;
  min-width : 1200px;
  width     : 100%;
}

.display-none {
  display: none;
}

.input-file-container-hide input[type="file"] {
  display: none;
}

.font-color-white {
  color : $white;
}

.cursor-pointer {
  cursor : pointer;
}

.bg-error {
  background-color: #fae3e2;
}

.disabled-text-color {
  color: lightgray;
}

//When a package has been submitted, we need to show the text from the input fields but not be editable.
.unEditable {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
}

//This is a transition class. Once the whole application is using bs-body-section, we can remove the existing css from the app.css and rename this class to be body-section
.bs-body-section {
  min-height  : 800px;
}

.overflowX {
  overflow-x: auto;
}

.thumbnailBackgroundImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

// Notification area
.notification-area > div {
  margin-top: 1rem;
}

.notification-area > div:first-child {
  margin-top: 0;
}

// icons
.checkCircle {
  font-size : 20px;
  color     : #12992E;
}

.deleteIcon {
  font-size: 25px;
  width: 25px;
  height: 25px;
  line-height: 0;
}

// to prevent word line breaks and properly wrap words.
.saved-notes {
  word-wrap: break-word;
  white-space: pre-wrap;
}

// outlined buttons without the border
@each $color, $value in $theme-colors {
  .btn-clear-outline-#{$color} {
    @include button-clear-outline-variant($value);
  }
}

// outlined buttons without the border
@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    @include button-link-variant($value);
  }
}

@each $color, $value in $underline-link-buttons {
  .btn-underline-link-#{$color} {
    @include button-link-variant($value, underline);
  }
}

@each $color, $value in $underline-link-buttons {
  .btn-link-#{$color}-toDarkPrimary {
    @include button-link-variant($value,null,darken($primary, 7.5%));
  }
}

// Header tags
.magento-h1 {
  font-size   : 3rem;
  font-weight : 300;
  line-height : 3.25rem;
}

.magento-h2 {
  font-size   : 2.25rem;
  line-height : 2.5rem;
}

.magento-h3 {
  font-size   : 1.75rem;
  font-weight : 300; /* normal is too bold */
  line-height : 2.1875rem;
}

.magento-h4 {
  font-size   : 1.125rem;
  font-weight : 700;
  line-height : 1.375;
}

.magento-h5 {
  font-size   : 1.125rem;
  font-weight : 700;
  line-height : 2.1875rem;
}

.magento-h6 {
  font-size   : 1rem;
  font-weight : 700;
  line-height : 1rem;
}

/* even though this is the default size text for the app, we have to have a class for it for the modal windows */
.magento-h7 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.magento-h8 {
  font-size: 0.75rem;
  line-height: 1rem;
}

label {
  font-size: 1rem; // to match comps, use: 0.875rem;
}

// p {
//   font-size: 0.875rem; // to match comps, use: 0.875rem;
// }

.magento-label {
  font-size: 1rem; // to match comps, use: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
}

// modal max height
.modal-max-height {
  max-height: calc(100vh - 8rem);
}

// modal max width
@media screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 550px !important;
  }
}

@media screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 960px !important;
  }
}

// Main nav
.navigation-top-button-profile, .navigation-top-button-nav, .devportal-button-orange {
  background      : transparent none no-repeat;
  border          : none transparent 0;
  color           : #5f6061;
  cursor          : pointer;
  font-size       : 15px;
  font-weight     : 500;
  outline         : none;
  padding         : 0 15px;
  text-decoration : none;
}

nav .navbar-brand {
  margin-right: 2rem;
}

nav .nav-item {
  font-weight: 500;
  font-size: 1rem;
}

//Page Level Horizontal Nav
.page-level-nav-item {
  min-width: 180px;
}

.page-level-nav-item > .nav-link.active { //FIXME: BS is overridding these styles even though these being made specific . Will revisit to remove important
  font-weight: 600 !important ;
  background-color: white !important;
  border-color: white !important;
}

.page-level-nav-item > a {
  color: black;
}

// Footer
footer .marketplace-legal-links li {
  margin:0 !important;
  display: inline-block; 
  text-decoration: none;
  border-right-style: solid;
  border-right-color: $white;
  border-right-width: 1px;
  padding: 0 5px 0 5px;
}

footer .marketplace-legal-links li a {
  color: $white;
  font-weight: normal !important;
}

footer .marketplace-legal-links li:first-child {
  padding-left: 0px;
}

footer .marketplace-legal-links li:last-child {
  border-right-color: none;
  border-right-style: none;
  padding-right: 0px;
}

/* Links will be styled via react-spectrum
// A anchor tags
// If we need 1 more custom anchor tag style, then create a mixin
a.link-tertiary {
  color: $tertiary;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a.link-tertiary:hover {
    color: darken($tertiary, 15%) ;
    text-decoration: none; }

a.link-tertiary:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a.link-tertiary:not([href]):not([tabindex]):hover, a.link-tertiary:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a.link-tertiary:not([href]):not([tabindex]):focus {
    outline: 0; }

a.link-underline-tertiary {
  color: $tertiary;
  text-decoration: underline !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a.link-underline-tertiary:hover {
    color: darken($tertiary, 15%) ;
    text-decoration: underline !important; }

a.link-underline-primary:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: underline !important; }
  a.link-underline-primary:not([href]):not([tabindex]):hover, a.link-primary:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: underline !important; }
  a.link-underline-primary:not([href]):not([tabindex]):focus {
    outline: 0; }
    a.link-underline-primary {
      color: $primary;
      text-decoration: underline !important;
      background-color: transparent;
      -webkit-text-decoration-skip: objects; }
      a.link-underline-primary:hover {
        color: darken($primary, 15%) ;
        text-decoration: underline !important; }
    
    a.link-underline-primary:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: underline !important; }
      a.link-underline-primary:not([href]):not([tabindex]):hover, a.link-primary:not([href]):not([tabindex]):focus {
        color: inherit;
        text-decoration: underline !important; }
      a.link-underline-primary:not([href]):not([tabindex]):focus {
        outline: 0; }
        */

// cards / wells
.magento-card-list {
 list-style: none;
  padding: 0;
}
ul.magento-product-specs {
  width: 100%;
  margin: 0;
  padding: 0;
}

.magento-card-list > .card {
  background-color: white;
  border: solid $light 1px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(174,175,177,0.41);
}

.card-min {
  min-height: 85px;
}

.magento-product-name::before {
  content: ' Name : ';
}

.magento-product-specs {
  list-style: none;
}

ul.magento-product-specs li {
  min-width: 50px;
  margin-right: 1rem;
}

.magento-product-specs p {
  margin: 0;
}

.report-card > .card {
  border: solid $light 1px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px 0 rgba(174,175,177,0.41);
}

.report-card-min {
  height: 130px;
  width: 220px;
}

// thumbnails
.thumbnail-lrg {
  width: 180px;
  height: 180px;
}

// buttons
.disabled-secondary:disabled {
  background: $secondary !important;
  border-color: $secondary  !important;
  opacity: 100;
}

.disabled-outline-secondary:disabled {
  border-color: $secondary !important;
  color: $secondary !important;
}

.currency:before {
  content: '$';
}

//Report Page Styles
.graph-area {
  min-height: 540px;
}

.payout-downloads {
  color: #4A90E2;
}

// richtext area
.richtextarea.form-control {
  height: 300px;
  padding: 0 0 0 .75rem;
  overflow: hidden;
  resize: none;
}

.toolbar-control {
  width: 100%;
}

// richtext editor
.editor-control {
  height: 100%;
  max-height: 245px;
}

.editor-control  div {
  border: transparent;
}

.padding-bottom-15 {
  padding-bottom: 1.5rem!important;
}

.padding-35 {
  padding: 3.5rem!important;
}

.font-size-14 {
  font-size: 14px!important;
}

.font-size-28 {
  font-size: 28px!important;
}

.font-adobe-clean {
  font-family: 'adobe-clean';
}
.font-adobe-clean-light {
  font-family: 'adobe-clean-light';
}
