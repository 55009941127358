.context-menu {
    display   : flex;
    flex-flow : row nowrap;
    position  : relative;
}

.menu-name {
    display   : flex;
    flex-flow : row nowrap;
}

.menu-content-container {
    align-items      : flex-start;
    background-color : #f2f3f3;
    border           : solid #949496 1px;
    display          : flex;
    flex-flow        : column nowrap;
    height           : 105px;
    justify-content  : space-around;
    padding          : 15px 20px;
    position         : absolute;
    right            : 0;
    top              : 40px;
    width            : 195px;
    z-index          : 999;
}

.menu-content-container:after,
.menu-content-container:before {
    bottom         : 100%;
    content        : " ";
    height         : 0;
    right          : 28%;
    position       : absolute;
    pointer-events : none;
    width          : 0;
}

.menu-content-container:after {
    border              : 15px solid rgba(242, 243, 243, 0);
    border-bottom-color : #f2f3f3;
    margin-left         : -15px;
}

.menu-content-container:before {
    border              : 15.5px solid rgba(38, 162, 170, 0);
    border-bottom-color : #949496;
    margin-left         : -16px;
}

.menu-content, .menu-content-notification {
    background      : transparent none no-repeat;
    border          : none transparent 0;
    cursor          : pointer;
    margin          : 0;
    outline         : none;
    padding         : 0;
}

.menu-content {
    color           : #26a2aa;
    cursor          : pointer;
    font-size       : 15px;
    text-decoration : none;
}
.menu-content:hover {
    color           : #26a2aa;
}
.menu-content-notification {
    color           : #ffffff;
    cursor          : pointer;
    font-size       : 16px;
    font-weight     : 500;
    margin          : 0;
}
