//Styles related to product main grid , version grid , test report grid , reports grid etc will be here

/** Product Main Grid **/
.product-grid-header {
  align-items     : center;
  display         : flex;
  flex-flow       : row nowrap;
  height          : 28px;
  justify-content : space-between;
  padding-bottom  : 30px;
  width           : 100%;
}

.grid-row-disabled {
  color: lightgray;
}

.product-grid-row {
  max-height: 65px;
}

/** Shared Packages Selector Header Styles **/

.product-grid-col-sel-extension {
  width : 200px;
}

.product-grid-col-sel-id {
  width: 150px;
}

.product-grid-col-sel-platform {
  width: 80px;
}

/** Product Grid Header Styles **/

.product-grid-col-platform {
  width: 7%;
}

.product-grid-col-num-versions,
.product-grid-col-version {
  width: 11%;
}

.product-grid-col-modified-at {
  width: 13%;
}

.product-grid-col-actions {
  width      : 80px;
  cursor     : pointer;
  text-align : center;
}

/** Product Version Grid Header Styles **/

.product-version-grid-col-version,
.product-version-grid-col-submission {
  width : 13%;
}

.product-version-grid-col-resubmission {
  width : 18%
}

.product-version-grid-col-modified-at {
  width : 13%
}

/** Test Report Header Styles **/

.test-report-grid-col-type,
.test-report-grid-col-details {
  width : 25%;
}

.test-report-grid-col-date,
.test-report-grid-col-result {
  width : 23%
}

/** misc **/

.magento-table {
  border: $border-width solid $border-color!important;
}

.magento-table > .row-border:first-child {
  background-color: $light;
}

.magento-table > .row-border:first-child > div {
  font-size : 0.875rem;
  line-height : 1.5rem;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center!important;
  justify-content: space-between;
}

.row-border {
  border-bottom: $border-width solid $border-color!important;
}

.row-border > div {
  border-right: $border-width solid $border-color!important;
}

.row-border > div:last-child {
  border-right:none !important;
}

.row-border:last-child {
  border-bottom:none !important;
}