@mixin button-clear-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: rgba($color, 0);
  
    &:hover {
      color: $color-hover;
      background-color: $active-background;
      border-color: $active-border;
    }
  
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  
    &.disabled,
    &:disabled {
      color: $color;
      background-color: transparent;
    }
  
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
      color: color-yiq($active-background);
      background-color: $active-background;
      border-color: $active-border;
  
      &:focus {
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows and $btn-active-box-shadow != none {
          box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
        } @else {
          box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
        }
      }
    }
  }


  @mixin button-link-variant($color, $text-decoration:none, $color-hover: darken($color, 7.5%), $active-background: $color, $active-border: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: none;
    text-decoration: $text-decoration;
    @if $text-decoration == underline {
      &:hover {
        color:  darken($color, 15%);
        text-decoration: $text-decoration !important;
      }
     
    } @else {
      &:hover {
        color:  $color-hover;
      }
    }
    
  
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }
  