/*****         App Wide Styling         *****/

.magento-developer-logo {
    background : transparent url("../assets/images/magento/Adobe_Corporate_Horizontal_Lockup_Red_RGB.svg") no-repeat center;
    height     : 25px;
    padding    : 0 7px;
    width      : 110px;
}

.magento-logo {
    cursor     : pointer;
    height     : 25px;
    max-height : 25px;
    width      : 110px;
}

/*****   END   App Wide Styling   END   *****/



/***         Headings         ***/

.h1 {
    font-size   : 48px;
    font-weight : 300;
    line-height : 52px;
}

.h2 {
    font-size   : 36px;
    line-height : 40px;
}

.h3 {
    font-size   : 28px;
    font-weight : 300; /* normal is too bold */
    line-height : 35px;
}

.h4 {
    font-size   : 20px;
    font-weight : 500;
    line-height : 22px;
}

.h5 {
    font-size   : 18px;
    font-weight : 500;
    line-height : 35px;
}

.h6 {
    font-size   : 16px;
    font-weight : 500;
}

/* even though this is the default size text for the app, we have to have a class for it for the modal windows */
.h7 {
    font-size   : 14px;
    line-height : 18px;
}

.h8 {
    font-size : 12px;
}

/***   END   Headings   END   ***/



/***         Background styling         ***/

.background-repeat {
    background-repeat : repeat;
}

.background-no-repeat {
    background-repeat : no-repeat;
}

.background-position-center {
    background-position : center;
}

.background-size-cover {
    background-size : cover;
}

.background-color-white {
    background-color : #fff;
}

/***   END   Background styling   END   ***/



/***         pointer cursor         ***/

.cursor_pointer_grab {
    cursor : grab;
}

/***   END   pointer cursor   END   ***/



/***         Font Sizing         ***/

.font-size-25 {
    font-size : 25px;
}

/***   END   Font Sizing   END   ***/



/***         position         ***/

.position-bottom-0 {
    bottom : 0;
}

.position-left-0 {
    left: 0;
}

.position-right-0 {
    right: 0;
}

.position-top-0 {
    top: 0;
}

/***   END   position   END   ***/



/***         width         ***/

.width-px-20 {
    width : 20px;
}

/***   END   width   END   ***/



/***         z-index         ***/

.z-index-auto {
    z-index : auto;
}

.z-index-1 {
    z-index : 1;
}

.z-index-2 {
    z-index : 2;
}

/***   END   z-index   END   ***/



/* todo // JRP */
/***         Text Blocks         ***/

.paragraph1 {
    color       : #2b2b2c;
    font-size   : 16px;
    line-height : 28px;
    padding     : 10px 0;
}

.italicized {
    font-style : italic;
}

.bold {
    font-weight: 600;
}

.new-paragraph {
    color       : #2b2b2c;
    font-size   : 16px;
    line-height : 28px;
}

.new-paragraph1 {
    font-size   : 14px;
    font-weight : normal;
    line-height : 23px;;
    max-width   : 768px;
}

.new-labels {
    font-size   : 14px;
    font-weight : 500;
}



/***   END   Text Blocks   END   ***/




/***   Form Inputs   ***/

/* Checkboxes, Radio Buttons, and Drop Downs */






/*  -----     NEW STYLING     -----  */
/** WILL SLOWLY UPDATE EXISTING STYLING TO THIS UNIFIED ONE AS TIME GOES ON. **/

/* new headings */ /* new headings */ /* new headings */



/* new page/body styling */ /* new page/body styling */ /* new page/body styling */



.new-page-section {
    display         : flex;
    flex-flow       : column nowrap;
    justify-content : center;
    padding         : 30px 0;
    width           : 100%;
}

.new-body-panel-section {
    background-color : #ffffff;
    border           : solid 1px #cccccc;
    display          : flex;
    flex-flow        : column nowrap;
    padding          : 20px 20px 50px;
    margin           : 0 30px;
}

.new-body-section-centered-content {
    display     : flex;
    flex-flow   : column nowrap;
    align-items : center;
}

.new-body-section-centered-content > div {
    margin : 15px;
}

.new-body-header,
.new-body-footer {
    align-items : center;
    display     : flex;
    flex-flow   : row nowrap;
    padding     : 5px 0;
    width       : 100%;
}

.new-body-header {
    justify-content : space-between;
}

.new-body-footer {
    border-top      : 1px solid #cccccc;
    justify-content : flex-end;
    margin-top      : 30px;
    padding-top     : 30px;
}

.new-body-section {
    align-items     : flex-start;
    display         : flex;
    flex-flow       : column nowrap;
    justify-content : space-between;
    max-width       : 768px;
    padding         : 5px 0;
    width           : 100%;
}

.breadcrumb-container {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    padding         : 0 30px 5px;
}

.breadcrumb-items {
    display   : flex;
    flex-flow : row nowrap;
}


/* new button */ /* new button */ /* new button */

.new-btn-group {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : center;
    align-items: center;
}

.new-btn-small {
    font-size   : 13px;
    font-weight : 600;
    min-width   : 102px;
    padding     : 10px 20px;
}

.new-btn-medium {
    font-size   : 15px;
    font-weight : 600;
    min-width   : 126px;
    padding     : 15px 30px;
}

.new-btn-orange-solid,
.new-btn-orange-transparent-with-border,
.new-btn-orange-transparent-no-border,
.new-btn-teal-solid,
.new-btn-teal-transparent-with-border,
.new-btn-teal-transparent-no-border,
.new-btn-disabled-solid {
    align-items     : center;
    cursor          : pointer;
    justify-content : center;
    text-align      : center;
    text-decoration : none;
    vertical-align  : middle;
}

.new-btn-orange-solid,
.new-btn-teal-solid {
    border-radius : 2px;
    color         : #ffffff;
}

.new-btn-orange-solid {
    background-color : #f16321;
    border        : 1px solid #f16321;
}

.new-btn-orange-transparent-with-border {
    background-color: #ffffff;
    color: #f16321;
    border: 1px solid #f16321;
    border-radius: 2px;
}

.new-btn-orange-transparent-no-border {
    background-color: transparent;
    color: #f16321;
}


.new-btn-teal-solid {
    color            : #ffffff;
    background-color : #26a2aa;
}

.new-btn-teal-transparent-with-border {
    background-color: #ffffff;
    color: #26a2aa;
    border: 1px solid #26a2aa;
    border-radius: 2px;
}

.new-btn-teal-transparent-no-border {
    background-color: transparent;
    color: #26a2aa;
}

.new-btn-disabled-solid {
    background  : #949496;;
    border      : 1px solid #949496;
    color       : #f3f3f3;
}


/* new div table sections */ /* new div table section */ /* new div table sections */
.new-div-table {
    border-collapse : collapse;
    display         : table;
    table-layout    : fixed;
    width           : 100%;
}

.new-div-table-header {
    background-color : #f2f3f3;
    display          : table-row;
    font-size        : 16px;
    font-weight      : 500;
    height           : 45px;
    line-height      : 16px;
}

.new-div-table-body {
    display : table-row-group;
}

.new-div-table-row {
    display    : table-row;
    max-height : 65px;
}

.new-div-table-cell {
    border         : solid 1px #cccccc;
    display        : table-cell;
    overflow       : hidden;
    padding        : 10px 10px;
    /*text-align     : center;*/
    text-overflow  : ellipsis;
    vertical-align : middle;
    white-space    : pre;
}

.new-div-table-cell-sort {
    display   : flex;
    flex-flow : column nowrap;
}

.sort-arrow-up,
.sort-arrow-down {
    cursor    : pointer;
    font-size : 23px;
    margin    : -6px 0;
}

.sort-arrow-up.active,
.sort-arrow-down.active {
    color     : #f16321;
}

.new-div-table-cell-sort-container {
    align-items     : center;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
}

.successful-upload{
    border-radius: 3px;
    background-color: #12992E;
    color:#ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-product-live-indicator{
    height: 24px;
    width: 72px;
}

/* https://www.npmjs.com/package/react-tooltip  */
.tooltip-disabled-elements{
    font-size: 16px !important;
    color: #2B2B2C !important;
    /* height: 70px; */
    width: 280px;
    border: 1px solid #949496;
    border-radius: 2px;
    background-color: #F2F3F3 !important;
}


















/*   Technically Global CSS classes since every child component should inherit these   */



/*****     App Headings     *****/


/*****     Component Sections     *****/

.page-section, .body-section {
    align-items      : center;
    background-color : #f2f3f3;
    display          : flex;
    flex-flow        : column nowrap;
    justify-content  : flex-start;
    padding          : 0;
}

.body-section {
    min-height  : 800px;
    width       : 100%;
}

.body-header {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    margin          : 15px 0;
    width           : 100%;
}

.body-main {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}


/* Validation Style */
.validation-error-text {
    color : #ff0000;
    font-size: 14px;
}

/* Div Tables */

.div_access_keys_table_cell_name {
    overflow : hidden;
    width    : 200px;
}

.div_access_keys_table_cell_key_data {}

.div_access_keys_table_cell_actions {
    width : 100px;
}

/*****     App Buttons     *****/

.btn-orange-solid, .btn-orange-transparent, .btn-orange-transparent-no-border,
.btn-teal-solid, .btn-teal-transparent, .btn-teal-transparent-no-border,
.btn-grey-solid,
.navigation-top-btn,
.landing-page-link-button {
    align-items     : center;
    border-radius   : 2px;
    cursor          : pointer;
    display         : flex;
    font-size       : 15px;
    justify-content : center;
    text-align      : center;
    text-decoration : none;
    vertical-align  : middle;
}

/*15 top and 30 side padding*/

.btn-orange-transparent-no-border, .btn-orange-transparent,
.btn-teal-transparent-no-border, .btn-teal-transparent {
    background  : transparent;
    border      : none;
    color       : #f16321;
    font-weight : 500;
}

.btn-orange-transparent {
    background : transparent;
    border     : 1px solid #f16321;
    color      : #f16321;
}

.btn-teal-transparent-no-border {
    color : #26a2aa;
}

.btn-teal-transparent {
    background : white;
    border     : 1px solid #26a2aa;
    color      : #26a2aa;
}

.btn-orange-solid, .btn-teal-solid, .btn-grey-solid,
.landing-page-link-button {
    color : #ffffff;
}

.btn-orange-solid,
.landing-page-link-button {
    background-color : #f16321;
    border           : 1px solid #f16321;
}

.btn-teal-solid {
    background-color : #26a2aa;
    border           : none;
}

.btn-grey-solid {
    background  : #949496;
    border      : 1px solid #949496;
}

.navigation-top-btn {
    font-size : 13px;
    height    : 29px;
    width     : 113px;
}

.landing-page-link-button {
    font-size : 15px;
    height    : 41px;
    width     : 174px;
}

.close-button {
    cursor    : pointer;
    font-size : 25px;
    padding   : 12px 12px 0 15px;
    position  : absolute;
    right     : 0;
    top       : 0;
}

.delete-icon-button {
    color     : #949496;
    cursor    : pointer;
    font-size : 25px;
    position  : absolute;
}

.btn-grey-disabled {
    background: #9494964d;
    border: 1px solid #9494964d;
    cursor    : auto;
}

/* checkboxes and radio buttons*/

.checkmark-container,
.radio_mark-container {
    cursor              : pointer;
    display             : flex;
    flex-flow           : column nowrap;
    font-size           : 16px;
    justify-content     : center;
    line-height         : 22px;
    position            : relative;
    user-select         : none;
    -moz-user-select    : none;
    -ms-user-select     : none;
    -webkit-user-select : none;
}

.checkmark-container input,   /* hide the browsers default checkbox */
.radio_mark-container input { /* hide the browsers default radio button */
    opacity  : 0;
    position : absolute;
}

.checkmark,
.radio_mark {
    background-color : #ffffff;
    border           : 1px solid #aeafb1;
    left             : 0;
    position         : absolute;
    top              : 0;
}

.checkmark:after,   /* create the checkmark checked indicator */
.radio_mark:after { /* create the dot/circle indicator. Its only shown when checked */
    content  : "";
    position : absolute;
    display  : none;
}

.checkmark-container {
    height        : 21px;
    margin-bottom : 4px;
    padding-left  : 30px;
    padding-top   : 1px;
}

.checkmark { /* create a custom styled checkbox */
    border-radius : 2px;
    height        : 20px;
    left          : 0;
    position      : absolute;
    top           : 0;
    width         : 20px;
}

.checkmark-container:hover input ~ .checkmark { /* on mouse-over, change the background to gray */
    background-color : #ffffff;
}

.checkmark-container input:checked ~ .checkmark { /* when checked, change background to blue */
    background-color : #4b9ed2;
}

.checkmark-container input:checked ~ .checkmark:after { /* show the checkmark when checked */
    display : block;
}

.checkmark-container .checkmark:after { /* style the checkmark indicator */
    border            : solid white;
    border-width      : 0 1px 1px 0;
    height            : 10px;
    left              : 7px;
    top               : 3px;
    width             : 5px;
    transform         : rotate(45deg);
    -ms-transform     : rotate(45deg);
    -webkit-transform : rotate(45deg);
}

.radio_mark-container { /* customize the container label */
    padding-left        : 27px;
}

.radio_mark { /* create a custom radio button */
    border-radius : 50%;
    height        : 21px;
    width         : 21px;
}

.radio_mark-container:hover input ~ .radio_mark { /* on mouse-over change the background to grey */
    background-color : #cccccc;
}

.radio_mark-container input:checked ~ .radio_mark { /* when the radio is selected change the background to blue */
    background-color : #4b9ed2;
}

.radio_mark-container input:checked ~ .radio_mark:after { /* show the indicator when checked */
    display : block;
}

.radio_mark-container .radio_mark:after { /* style the indicator with a dot and circle */
    background    : white;
    border-radius : 50%;
    height        : 9px;
    left          : 5px;
    top           : 5px;
    width         : 9px;
}


/* link buttons */
.link, .danger_link {
    color           : #0d66d0; /* react-spectrum color for links*/
    cursor          : pointer;
    font-size       : 14px;
    text-decoration : underline;
}

.danger_link {
    color: #ff0000;
}


/* Text Inputs */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color        : #aeafb1;
    font-weight  : 100;
    opacity      : 1; /* Firefox */
    padding-left : 14px;
    text-indent  : 0;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color       : #aeafb1;
    font-weight : 100;
    text-indent : 0;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color       : #aeafb11;
    font-weight : 100;
    text-indent : 0;
}


/* Zero State Components */

.zero-state-grid {
    align-items      : center;
    background-color : #fff;
    border           : 1px solid #aeafb1;
    display          : flex;
    flex-flow        : column nowrap;
    height           : 100px;
    justify-content  : flex-start;
    margin           : 10px 0 10px;
    padding          : 10px 0 68px;
    width            : 100%;
}

.zero-state-heading {
    padding: 30px 0 30px;
}

.zero-state-button {
    font-size : 15px;
    padding   : 10px 20px;
}



/* Modals */

.ReactModal__Body--open {
    height   : 100%;
    overflow : hidden;
    width    : 100%;
    background-color: transparent;
}

.modal-content,
.access-keys-modal-content,
.product-modal-content,
.product-modal-content-create,
.workflow-modal-content,
.product-new-version-modal,
.product-create-version-modal,
.product-sp-new-version-modal,
.product-submission-confirmation-modal,
.remove-from-store-confirmation-modal,
.tos-model-content,
.tos-model-content-decline {
    background-color : #ffffff;
    border           : 1px solid #f2f3f3;
    box-shadow       : 0 2px 4px 0 rgba(174, 175, 177, 0.41);
    box-sizing       : border-box;
    display          : flex;
    flex-flow        : column nowrap;
    justify-content  : space-around;
    margin-bottom    : 20px;
    position         : absolute;
    top              : 59px;
}

.access-keys-modal-content {
    align-items  : center;
    height       : 300px;
    padding      : 10px 25px 15px;
    width        : 516px;
}

.tos-model-content {
    align-items  : center;
    height       : 325px;
    padding      : 10px 25px 15px;
    width        : 650px;
}

.tos-model-content-decline {
    align-items  : left;
    height       : 225px;
    padding      : 10px 25px 15px;
    width        : 580px;
}

.product-modal-content {
    align-items  : center;
    height       : 704px;
    padding      : 10px 25px 15px;
    width        : 516px;
}

.product-modal-content-create {
    align-items  : center;
    padding      : 10px 25px 15px;
    width        : 516px;
}

.workflow-modal-content {
    align-items : center;
    height      : 635px;
    padding-top : 20px;
    width       : 782px;
}

.modal-overlay,
.access-keys-modal-overlay,
.product-modal-overlay,
.workflow-modal-overlay {
    align-items      : center;
    background-color : rgba(255, 255, 255, 0.75);
    bottom           : 0;
    display          : flex;
    justify-content  : center;
    left             : 0;
    overflow         : scroll;
    position         : absolute;
    right            : 0;
    top              : 0;
}

.product-new-version-modal{
    height  : 478px;
    padding : 10px 25px 15px;
    width   : 516px;
}

.product-create-version-modal {
    align-items     : center;
    justify-content : space-between;
    padding         : 25px 25px 15px;
    width           : 516px;
}

.product-create-version-modal-sm {
    height : 395px;
}

.product-create-version-modal-lrg {
    height : 478px;
}

.product-sp-new-version-modal{
    height  : 690px;
    padding : 10px 25px 15px;
    width   : 516px;
}

.product-submission-confirmation-modal{
    height  : 270px;
    padding : 10px 25px 15px;
    width   : 560px;
    align-items  : center;
}

.remove-from-store-confirmation-modal{
    height  : 370px;
    padding : 10px 25px 15px;
    width   : 660px;
}

/* Forms */
.form-header {
    align-items     : center;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    padding         : 55px 0 25px 0;
    width           : 100%;
}

.form-header-buttons {
    align-items     : flex-end;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-around;
    width           : 470px;
}

.form-body {
    align-items     : flex-start;
    display         : flex;
    flex-flow       : column nowrap;
    justify-content : space-between;
    width           : 100%;

}

.form-body-text {
    color       : #2b2b2c;
    font-size   : 16px;
    line-height : 28px;
}

.form-radio-buttons {
    display         : flex;
    flex-flow       : column nowrap;
    height          : 132px;
    justify-content : space-between;
    padding-top     : 25px;
    width           : 100%;
}

/* Report Page Styles*/

.report-sales-tab-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    cursor     : pointer;
    margin     : 0;
    padding    : 0;
}

.report-sales-tab {
    align-items      : center;
    background-color : transparent;
    color            : #2b2b2c;
    display          : flex;
    font-size        : 15px;
    font-weight      : 500;
    height           : 32px;
    line-height      : 15px;
    padding-left     : 35px;
    min-width       : 180px;
}


.report-sales-tab-selected {
    background-color : #ffffff;
    border-color     : #ffffff;
    font-size        : 18px;
    font-weight      : 500;
}

.dropdown-item:active {
    color: #2b2b2c !important;
    text-decoration: none;
    background-color: #ffffff !important;
}



/* misc */

/* TO BE DELETED */
/* TO BE DELETED */
/* TO BE DELETED */


.height_41 {
    height: 41px;
}

.height_29 {
    height: 29px;
}

.width_144 {
    width: 144px;
}

.width_154 {
    width: 154px;
}

.margin_10 {
    margin: 10px;
}

.padding_sides_10 {
    padding: 10px;
}

/* TO BE DELETED */
/* TO BE DELETED */
/* TO BE DELETED */



/* Buttons Library  */

/* Notification block styles . Will be moved to NotificationBlock.css eventually */
/* Bootstrap has a alert classes that would cover this and other alert levels.  RC */
.successMessageBlock {
    color: #FFFFFF;
    background-color: #107154;
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}


/* Misc styles */
.vh-100 {
    height : 100vh;
}

.pe-none {
    pointer-events: none;
}


/* These are spectrum styled buttons to be used for all modals in the app . react-spectrum buttons do not work with react-modal. modals will be handled in Phase 2 - PS */
.custom-spectrum-Button  {
    border-width: 2px;
    border-style: solid;
    border-radius: 16px;
    min-height: 32px;
    height: auto;
    padding: 4px 14px;
    padding-bottom: 4.5px;
    padding-top: 3.5px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
}

.custom-spectrum-Button--cta  {
    background-color: #1473e6;
    border-color: #1473e6;
    color: #fff;
}
.custom-spectrum-Button--primary {
    background-color: transparent;
    border-color: #505050;
    color: #505050;
}
.custom-spectrum-Button--primary:hover  {
    background-color: #505050;
    border-color: #505050;
    color: #fff;
}

.custom-spectrum-Button--cta:hover {
    background-color: #0d66d0!important;
    border-color: #0d66d0;
    color: #fff;
}
.custom-spectrum-Button--cta-disabled  {
    background-color: #747474;;
    border-color: #747474;;
    color: #fff;
}


