.block-warning,
.block-info,
.block-error,
.block-notification {
    align-items      : center;
    min-height       : 60px;
    width            : 100%;
    display          : flex;
    flex-flow        : row nowrap;
}

.block-warning {
    background-color : #fbedd9;
    border           : 1px solid #c27500;
    color            : #c27500;
    font-size        : 16px;
}

.block-error {
    background-color : #d7373f; /* This is negative color of react-spectrum  */
    border           : 1px solid #c27500;
    color            : #ffffff;
    font-size        : 16px;
}

.block-info {
    background-color : #2680eb;  /* This is information color of react-spectrum  */
    border           : 1px solid #2680eb;
    color            : #ffffff;
    font-size        : 16px;
}

.block-notification {
    background-color : #dcf3e1;
    border           : 1px solid #0f7d26;
    color            : #0f7d26;
    font-size        : 16px;
}

.icon-warning, .icon-error, .icon-info, .icon-notification {
    padding   : 0 10px 0 15px;
    font-size : 25px;
}

.icon-error {
    color : yellow;
}

.custom-text-warning, .custom-text-error, .custom-text-info, .custom-text-notification {
    line-height : 16px;
}

.custom-text-error {
    color: #000000;
}

.close-info{
    font-size   : 25px;
    padding     : 12px;
    margin-left : auto;
}

.icon-help{
    font-size : 25px;
}

.EnvNotification {
    line-height: 24px;
}

.EnvNotification a {
    color: #800080;
}
