.product-tabs {
    display   : flex;
    flex-flow : row nowrap;
    height    : 100%;
    border: 1px solid #AEAFB1;
}

.product-tabs-tab a{
    color : #ddd;
}

.product-tabs-tab {
    height    : 45px;
    display   : flex;
    align-items: center;
    padding-left: 20px;
    color : #ddd;
    cursor : pointer;
}

.product-tabs-tab-header{
    height    : 45px;
    display   : flex;
    align-items: center;
    padding-left: 20px;
    font-size  :15px;
    font-weight : 500;
    color : #ddd;
}
.product-tabs-tab-list {
    cursor     : pointer;
    list-style : none;
    margin     : 0;
    padding    : 0;
    width      : 240px;
    border-right: 1px solid #AEAFB1;
    background-color : #323333;;
}

.product-tabs-selected {
    background-color : #444;
    color            : #ddd;
    font-size        : 15px;
    font-weight      : 500;
}

.product-tabs-tab-panel {
    width : 0;
    padding-top:20px;
    padding-left:20px;
    background-color: #ffffff;
}

.product-tab-panel-selected {
    width : 100%;
}

ul#notifications > li ul {
    list-style-type: disc;
}

ul#notifications .list-group-item:first-child {
    border-top: 0;
}

#notifications .list-group-item a {
    color: #0d66d0 !important;
}
