.navigation-bottom {
    background-color : #454646;
    display          : flex;
    flex-flow        : row nowrap;
    max-height       : 103px;
    padding          : 32px 30px;
}

.navigation-bottom-header {}
.navigation-bottom-footer {}

.navigation-bottom-body {
    align-items     : center;
    display         : flex;
    flex-flow       : row wrap;
    justify-content : space-between;
    width           : 100%;
}

.navigation-bottom-anchor {
    cursor : pointer;
}

.navigation-bottom-text {
    color     : #ffffff;
    font-size : 14px;
}

.social-media-img {
    cursor : pointer;
    height : 30px;
    width  : 30px;
}

.navigation-bottom-body-magento_copyright {
    align-items     : center;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    max-width       : 415px;
    width           : 415px;
}

.navigation-bottom-body-links {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-around;
    max-width       : 333px;
    width           : 333px;
}

.navigation-bottom-body-social_media_icons {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    max-width       : 155px;
    width           : 155px;

}