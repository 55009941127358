@font-face {
    font-family: 'adobe-clean';
    src: url('./AdobeClean-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'adobe-clean-light';
    src: url('./AdobeClean-Light.otf');
    font-weight: normal;
    font-style: normal;
}
