.launch-logo{
    width:150px;
}
.bgcc{
    color:#ffffff;
    height:200px;
    padding-top: 20px;
    padding-left: 20px;
}

#launch-partner-form svg, #launch-internal-form svg{
    box-sizing: content-box;
    top: var(--spectrum-global-dimension-size-100);
}
.ack-container{
    flex:auto;
}
.ack_body{
    min-height:900px;
}

