.landing-page-body-title {
    color     : #2b2b2c;
    font-size : 36px;
    padding   : 20px 10px 15px;
}

.landing-page-body-getting-started {
    align-items     : center;
    display         : flex;
    flex-flow       : column nowrap;
    justify-content : center;
    width           : 100%;
}

.landing-page-body-getting-started-tabs {
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-around;
    padding         : 10px 0 30px;
    width           : 100%;
}


.process-overview-tab-body {
    display: flex;
    flex-flow: column nowrap;
    font-size: 16px;
    justify-content: center;
    padding: 0 0 20px;
}


.process-overview-tab-header {
    font-size : 28px;
    padding   : 14px 0;
}

.landing-page-react-tabs {
    width : 100%;
    padding: 0 30px;
}

.landing-page-react-tabs__tab-list {
    align-items      : center;
    background-color : #f2f3f3;
    cursor           : pointer;
    display          : flex;
    flex-flow        : row nowrap;
    height           : 47px;
    justify-content  : flex-start;
    list-style       : none;
}

.landing-page-react-tabs__tab {
    align-items      : center;
    background-color : transparent;
    color            : #454646;
    display          : flex;
    font-size        : 16px;
    height           : 100%;
    padding          : 0 20px;
}

.landing-page-react-tabs__tab--selected {
    background    : #fff;
    border-radius : 2px 2px 0 0;
    color         : #f16321;
}

.landing-page-react-tabs__tab-panel {
    padding: 0 30px;
}

.landing-page-wells {
    font-size : 16px;
}

.flex-basis-33 {
    flex-basis: 33.33%;
}
